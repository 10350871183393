// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/configure.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/configure.tsx");
  import.meta.hot.lastModified = "1720694592052";
}
// REMIX HMR END

//"use client"

import { EyeClosedIcon, EyeOpenIcon, SymbolIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import toast from "react-hot-toast";
import { useRouteLoaderData } from "@remix-run/react";
export default function ConfigureServerModal({
  onClose,
  offerId
}) {
  _s();
  const {
    user
  } = useRouteLoaderData("root");
  if (user === null) return document.location.href = "/login";
  const [step, setStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const applicationsAutoDeployEnabled = true;
  const handleBackgroundClick = event => {
    onClose();
  };
  const handleModalClick = event => {
    event.stopPropagation();
  };
  const handleCheckout = async () => {
    if (isLoading) return;
    setLoading(true);
    try {
      const response = await fetch("https://api.deployserver.co/stripe/session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          accessToken: String(user.accessToken),
          serverOffer: offerId,
          billingCycle: Number(1),
          password: String(formData.password),
          hostname: String(formData.hostname),
          daily: false,
          chrome: Boolean(formData.chrome),
          discord: Boolean(formData.discord),
          cybersole: Boolean(formData.cybersole),
          burstaio: Boolean(formData.burstaio),
          pan: Boolean(formData.pan),
          theshitbot: Boolean(formData.theshitbot),
          kodai: Boolean(formData.kodai),
          ikigai: Boolean(formData.ikigai),
          adonis: Boolean(formData.adonis),
          ronsdataedit: Boolean(formData.ronsdataedit)
        })
      });
      const responseJSON = await response.json();
      setLoading(false);
      return window.location.href = responseJSON.url;
    } catch (error) {
      toast.error("An error has occurred");
      setLoading(false);
    }
  };
  const [formData, setFormData] = useState({
    hostname: "",
    region: "eu-paris-2",
    password: "",
    user: "Administrator",
    billingCycle: "Monthly",
    chrome: false,
    discord: false,
    cybersole: false,
    burstaio: false,
    pan: false,
    theshitbot: false,
    kodai: false,
    ikigai: false,
    adonis: false,
    ronsdataedit: false
  });
  const formDataIsValid = () => {
    for (const key in formData) {
      const value = formData[key];
      if (typeof value === "string" && value.trim() === "") {
        return false;
      }
    }
    return true;
  };
  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const generateString = (length, stringFor) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let stringName = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      stringName += charset[randomIndex];
    }
    switch (stringFor) {
      case "hostname":
        setFormData(prevFormData => ({
          ...prevFormData,
          hostname: stringName
        }));
        break;
      case "password":
        setFormData(prevFormData => ({
          ...prevFormData,
          password: stringName
        }));
        break;
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const nextStep = () => {
    if (formDataIsValid()) {
      if ((formData.hostname || "").length < 5) {
        toast.error("Hostname must have at least 5 letters");
        return;
      }
      if ((formData.password || "").length < 5 || (formData.password || "").length > 15) {
        toast.error("Password must be between 5 and 15 characters long.");
        return;
      }
      setStep(1);
    } else {
      toast.error("Some fields are not complete");
    }
  };
  return <>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center no-doc-scroll" onClick={handleBackgroundClick}>
                <div className="w-[500px] mx-auto py-10" onClick={handleModalClick}>
                    <div className="bg-white shadow-md rounded-lg p-8 relative">
                        <button onClick={onClose} className="text-gray-600 hover:text-gray-900 transition ease-in-out duration-150 absolute right-0 mr-3 top-0 mt-3" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                        <h2 className="text-xl font-semibold mb-6 uppercase">
                            Deploy Server
                        </h2>

                        {step === 0 && <div>
                                <div className="relative mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hostname">
                                        Personalization
                                    </label>
                                    <label className="block text-gray-700 text-sm mb-1 font-semibold" htmlFor="hostname">
                                        Choose a hostname for your server *
                                    </label>

                                    <input className="appearance-none border rounded bg-gray-100 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="hostname" type="text" name="hostname" placeholder="" value={formData.hostname} onChange={handleInputChange} />
                                    <button title="Generate random hostname" onClick={() => {
                generateString(8, "hostname");
              }} className="absolute inset-y-0 right-0 mr-3 mt-[50px] flex items-center text-gray-500">
                                        <SymbolIcon />
                                    </button>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm mb-1 font-semibold" htmlFor="region">
                                        Region *
                                    </label>
                                    <select className="block appearance-none w-full bg-gray-100 border px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline" id="region">
                                        <option>eu-paris-2</option>
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm mb-1 font-semibold" htmlFor="region">
                                        Billing Cycle *
                                    </label>
                                    <select className="block appearance-none w-full bg-gray-100 border px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline" id="billingCycle">
                                        <option>Monthly</option>
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm mb-1 font-bold" htmlFor="username">
                                        Username
                                    </label>
                                    <label className="block text-gray-700 text-sm mb-1 font-semibold" htmlFor="username">
                                        Choose a username for server access *
                                    </label>
                                    <input className="appearance-none border bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" disabled id="username" type="text" value="Administrator" />
                                </div>
                                <div className="mb-6">
                                    <label className="block text-gray-700 text-sm mb-1 font-bold" htmlFor="password">
                                        Password
                                    </label>
                                    <label className="block text-gray-700 text-sm mb-1 font-semibold" htmlFor="password">
                                        Choose a password for server access *
                                    </label>
                                    <div className="relative border rounded bg-gray-100 w-full">
                                        <input className="appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-transparent pr-10" id="password" type={showPassword ? "text" : "password"} name="password" placeholder="•••••••••••" value={formData.password} onChange={handleInputChange} />
                                        <button title="Generate random password" onClick={() => {
                  generateString(15, "password");
                }} className="absolute inset-y-0 right-0 mr-3 flex items-center text-gray-500">
                                            <SymbolIcon />
                                        </button>
                                        <button onClick={togglePasswordVisibility} className="absolute inset-y-0 right-0 mr-10 flex items-center text-gray-500">
                                            {showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />}
                                        </button>
                                    </div>
                                </div>
                            </div>}

                        {step === 1 && <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-medium">
                                    Select the applications to be deployed
                                    <br />
                                    during server deployment.
                                </label>
                                <span className="text-sm font-medium mb-6 text-red-500">This feature is currently disabled, will be back soon.</span>
                             
                                <div className="flex items-center mb-2 mt-2">
                                    <img alt="Icon representing Google Chrome" className="mr-2" src="https://pbs.twimg.com/profile_images/1605280848089169920/IuDGmUk__400x400.png" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="chrome" type="checkbox" name="chrome" checked={formData.chrome} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="chrome">
                                        Google Chrome
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing Discord" className="mr-2" src="https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/636e0a69f118df70ad7828d4_icon_clyde_blurple_RGB.svg" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="discord" type="checkbox" name="discord" checked={formData.discord} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="discord">
                                        Discord
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing Cybersole" className="mr-2" src="https://pbs.twimg.com/profile_images/1443640433473642499/1PFOD83L_400x400.jpg" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="cybersole" type="checkbox" name="cybersole" checked={formData.cybersole} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="cybersole">
                                        Cybersole
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing Burst AIO" className="mr-2" src="https://pbs.twimg.com/profile_images/1482678540072108039/oxq9XjNY_400x400.jpg" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="burstaio" type="checkbox" name="burstaio" checked={formData.burstaio} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="burstaio">
                                        Burst AIO
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing Pan" className="mr-2" src="https://pbs.twimg.com/profile_images/1375159003483934727/SgEBlTyR_400x400.jpg" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="pan" type="checkbox" name="pan" checked={formData.pan} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="panaio">
                                        Pan
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing The Shit Bot" className="mr-2" src="https://pbs.twimg.com/profile_images/1635600392024203264/QpviRqbI_400x400.jpg" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="theshitbot" type="checkbox" name="theshitbot" checked={formData.theshitbot} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="theshitbot">
                                        The Shit Bot
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing Kodai" className="mr-2" src="https://pbs.twimg.com/profile_images/1241542362121166852/0hQnV9lV_400x400.png" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="kodai" type="checkbox" name="kodai" checked={formData.kodai} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="kodai">
                                        Kodai
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing Ikigai" className="mr-2" src="https://pbs.twimg.com/profile_images/1559025647275606017/TdRESzYW_400x400.jpg" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="ikigai" type="checkbox" name="ikigai" checked={formData.ikigai} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="ikigai">
                                        Ikigai
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing Adonis" className="mr-2" src="https://pbs.twimg.com/profile_images/1594763171218264074/EkxDTiuC_400x400.jpg" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="adonis" type="checkbox" width={"20px"} name="adonis" checked={formData.adonis} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="adonis">
                                        Adonis
                                    </label>
                                </div>
                                <div className="flex items-center mb-2">
                                    <img alt="Icon representing Rons Data Edit" className="mr-2" src="https://www.ronsplace.ca/contentsite/images/product/data-edit/icon_48.png" width={"20px"} />
                                    <input disabled={applicationsAutoDeployEnabled} className="form-checkbox h-5 w-5 text-[#1cbaf0]" id="ronsdataedit" type="checkbox" width={"20px"} name="ronsdataedit" checked={formData.ronsdataedit} onChange={handleInputChange} />
                                    <label className="ml-2 text-sm text-gray-700" htmlFor="ronsdataedit">
                                        Rons Data Edit
                                    </label>
                                </div>
                            </div>}

                        {isLoading && <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                                <svg aria-hidden="true" className="w-8 h-8 animate-spin fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="gray" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" />
                                </svg>
                            </div>}

                        <div className="flex items-center justify-between mt-6">
                            {step === 0 ? <button onClick={() => {
              nextStep();
            }} className="bg-green-600 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm" type="button">
                                    Next step {step + 1}/2
                                </button> : <>
                                    <button onClick={() => {
                setStep(0);
              }} className="bg-gray-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm" type="button">
                                        Next step {step + 1}/2
                                    </button>
                                    <button onClick={async () => {
                await handleCheckout();
              }} className="bg-green-600 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm" type="button">
                                        Continue to the payment
                                    </button>
                                </>}
                        </div>
                    </div>
                </div>
            </div>
        </>;
}
_s(ConfigureServerModal, "RkzMmv3DR/IIsCM5K6Vt5RwlE1U=", false, function () {
  return [useRouteLoaderData];
});
_c = ConfigureServerModal;
var _c;
$RefreshReg$(_c, "ConfigureServerModal");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;