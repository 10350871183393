// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/home.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/home.tsx");
  import.meta.hot.lastModified = "1720694592324";
}
// REMIX HMR END

import { useState } from "react";
import ConfigureServerModal from "~/components/configure";
import TypingAnimation from "~/components/typing-animation";
export default function Home() {
  _s();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offerIdSelected, setOfferIdSelected] = useState(0);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return <>
            <nav className="border-gray-200 bg-black">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                            DeployServer
                        </span>
                    </a>
                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="font-medium flex flex-col p-4 md:p-0 mt-4  rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0">
                            <li>
                                <a href="#" className="block py-2 px-3 text-white rounded md:bg-transparent md:p-0">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="#" className="block py-2 px-3 text-white rounded md:bg-transparent md:p-0">
                                    Discord
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard" className="block py-2 px-3 text-white rounded md:bg-transparent md:p-0">
                                    Dashboard
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="flex min-h-screen">
                <div className="flex-1 p-10">
                    <div className="max-w-5xl mx-auto">
                        <h1 className="text-5xl font-bold text-gray-800 mb-6">
                            Nothing could be simpler than
                            <br />
                            deploying Windows servers with Deploy!
                        </h1>
                        <p className="text-lg text-gray-600 mb-6">
                            <TypingAnimation />
                        </p>

                        <section className="bg-black rounded-lg">
                            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 text-white">
                                        Made for botters by botters!
                                    </h2>
                                    <p className="mb-5 font-light sm:text-xl text-gray-400">
                                        Choose the best offer according to your
                                        requirements.
                                        <br />
                                        Customized configuration possible via
                                        contact!
                                    </p>
                                </div>
                                <div className="space-y-8 lg:grid lg:grid-cols-3 gap-6 lg:space-y-0">
                                    <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 rounded-lg border shadow-lg border-gray-700 xl:p-8 bg-gray-800 text-white transition-transform transform hover:scale-105">
                                        <h3 className="mb-4 text-3xl font-bold text-white">
                                            Starter VM
                                        </h3>
                                        <p className="font-light sm:text-lg text-gray-300">
                                            Best option if you're planning to
                                            run <br />1 bot.
                                        </p>
                                        <div className="flex justify-center items-baseline my-8">
                                            <span className="mr-2 text-5xl font-extrabold text-white">
                                                16€
                                            </span>
                                            <span className="text-gray-400">
                                                /month
                                            </span>
                                        </div>
                                        <ul role="list" className="mb-8 space-y-4 text-left text-gray-200">
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>8 GB Ram DDR4</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>4 vCores</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>50 GB SSD Storage</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>10 Gbits/s Network</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>OS Windows 2019</span>
                                            </li>
                                        </ul>
                                        <button onClick={() => {
                    setOfferIdSelected(1);
                    openModal();
                  }} className="bg-white hover:bg-gray-300 text-black py-2 px-4 rounded transition duration-300">
                                            Configure now
                                        </button>
                                    </div>

                                    <div className="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border shadow-lg border-gray-700 xl:p-8 bg-gray-800 text-white transition-transform transform hover:scale-105">
                                        <h3 className="mb-4 text-3xl font-bold text-white">
                                            Medium VM
                                        </h3>
                                        <p className="font-light sm:text-lg text-gray-300">
                                            Best option if you're planning to
                                            run<br /> 2 bots.
                                        </p>
                                        <div className="flex justify-center items-baseline my-8">
                                            <span className="mr-2 text-5xl font-extrabold text-white">
                                                25€
                                            </span>
                                            <span className="text-gray-400">
                                                /month
                                            </span>
                                        </div>
                                        <ul role="list" className="mb-8 space-y-4 text-left text-gray-200">
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>16 GB Ram DDR4</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>8 vCores</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>100 GB SSD Storage</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>10 Gbits/s Network</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>OS Windows 2019</span>
                                            </li>
                                        </ul>
                                        <button onClick={() => {
                    setOfferIdSelected(2);
                    openModal();
                  }} className="bg-white hover:bg-gray-300 text-black py-2 px-4 rounded transition duration-300">
                                            Configure now
                                        </button>
                                    </div>

                                    <div className="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border shadow-lg border-gray-700 xl:p-8 bg-gray-800 text-white transition-transform transform hover:scale-105">
                                        <h3 className="mb-4 text-3xl font-bold text-white">
                                            Pro VM
                                        </h3>
                                        <p className="font-light sm:text-lg text-gray-300">
                                            Best option if you're planning to
                                            run more than 2 bots.
                                        </p>
                                        <div className="flex justify-center items-baseline my-8">
                                            <span className="mr-2 text-5xl font-extrabold text-ywhite">
                                                50€
                                            </span>
                                            <span className="text-gray-400">
                                                /month
                                            </span>
                                        </div>
                                        <ul role="list" className="mb-8 space-y-4 text-left text-gray-200">
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>32 GB Ram DDR4</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>12 vCores</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>200 GB SSD Storage</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>10 Gbits/s Network</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg className="flex-shrink-0 w-6 h-6 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>OS Windows 2019</span>
                                            </li>
                                        </ul>
                                        <button onClick={() => {
                    setOfferIdSelected(3);
                    openModal();
                  }} className="bg-white hover:bg-gray-300 text-black py-2 px-4 rounded transition duration-300">
                                            Configure now
                                        </button>
                                    </div>

                                    {isModalOpen && <ConfigureServerModal onClose={closeModal} offerId={offerIdSelected} />}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className="hidden md:block md:w-[400px] bg-white p-5 shadow-lg">
                    <div className="mb-10">
                        <h3 className="text-2xl font-semibold text-gray-800">
                            Available Softwares
                        </h3>
                        <p className="text-base text-gray-700 mb-6 mt-2">
                            List of software options that can be deployed when
                            the server is deployed.
                        </p>
                        <div className="bg-gray-100 p-5 rounded-lg">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://pbs.twimg.com/profile_images/1443640433473642499/1PFOD83L_400x400.jpg" alt="Cybersole logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Cybersole
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://pbs.twimg.com/profile_images/1635600392024203264/QpviRqbI_400x400.jpg" alt="TSB logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            The Shit Bot
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://pbs.twimg.com/profile_images/1482678540072108039/oxq9XjNY_400x400.jpg" alt="Burst AIO logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Burst AIO
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://pbs.twimg.com/profile_images/1375159003483934727/SgEBlTyR_400x400.jpg" alt="Pan AIO logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Pan
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://pbs.twimg.com/profile_images/1241542362121166852/0hQnV9lV_400x400.png" alt="Kodai logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Kodai
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://pbs.twimg.com/profile_images/1594763171218264074/EkxDTiuC_400x400.jpg" alt="Adonis logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Adonis
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://pbs.twimg.com/profile_images/1559025647275606017/TdRESzYW_400x400.jpg" alt="Ikigai logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Ikigai
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://pbs.twimg.com/profile_images/1605280848089169920/IuDGmUk__400x400.png" alt="Chrome logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Chrome
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/636e0a69f118df70ad7828d4_icon_clyde_blurple_RGB.svg" alt="Discord logo" className="rounded-full mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Discord
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-5 rounded-lg mt-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <img width="35px" src="https://www.ronsplace.ca/contentsite/images/product/data-edit/icon_48.png" alt="Rons Data Edit logo" className="mr-3" />
                                    <div>
                                        <div className="text-lg font-medium text-gray-800">
                                            Rons Data Edit
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="bg-gray-100 p-5 rounded-lg mt-2">
                         <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div>
                                    <div className="text-lg font-medium text-gray-800">More coming soon! :)</div>
                                </div>
                            </div>
                         </div>
                         </div> */}
                    </div>
                </div>
            </div>

            <footer className="shadow bg-black">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <a href="https://flowbite.com/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                                DeployServer
                            </span>
                        </a>
                        <ul className="flex flex-wrap items-center mb-6 text-medium sm:mb-0 text-gray-400">
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">
                                    About
                                </a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
                    <span className="block text-sm sm:text-center text-gray-400">
                        © 2023{" "}
                        <a href="/" className="hover:underline">
                            DeployServer
                        </a>
                        . All Rights Reserved.
                    </span>
                </div>
            </footer>
        </>;
}
_s(Home, "dtoKdZRObE/5gRx0uJk4Y1zIJlM=");
_c = Home;
var _c;
$RefreshReg$(_c, "Home");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;