// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/typing-animation.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/typing-animation.tsx");
  import.meta.hot.lastModified = "1720694591976";
}
// REMIX HMR END

import React, { useState, useEffect } from "react";
const TypingAnimation = () => {
  _s();
  const fullText = "Deploy a server in just a few clicks, we take care of everything including the installation of your favorite software to save you a lot of time.";
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [speed, setSpeed] = useState(25);
  useEffect(() => {
    let timer;
    if (isDeleting) {
      timer = setTimeout(() => {
        setText(fullText.substring(0, text.length - 1));
        setSpeed(50);
      }, speed);
    } else {
      timer = setTimeout(() => {
        setText(fullText.substring(0, text.length + 1));
      }, speed);
    }
    if (!isDeleting && text === fullText) {
      setTimeout(() => setIsDeleting(true), 5000);
    }
    if (isDeleting && text === "") {
      setIsDeleting(false);
      setSpeed(25);
    }
    return () => clearTimeout(timer);
  }, [text, isDeleting, speed]);
  return <div>{text}</div>;
};
_s(TypingAnimation, "Q3ktFapQ+WWjNtrC41VPN8yusg8=");
_c = TypingAnimation;
export default TypingAnimation;
var _c;
$RefreshReg$(_c, "TypingAnimation");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;